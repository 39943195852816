import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { TextBanner } from "../components/molecules/TextBanner";

// eslint-disable-next-line
export const CareersPostTemplate = ({
  content,
  contentComponent,
  description,
  title,
  helmet,
  live,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <TextBanner title={live ? title : `${title} (post closed)`} />
      <section className="section">
        {helmet || ""}
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <p style={{ fontStyle: "italic" }}>{description}</p>
              <PostContent content={content} />
            </div>
          </div>
        </div>
        <div className="columns is-centered">
          <Link
            className="button is-rounded is-medium has-text-info"
            disabled={!live}
            to={
              live &&
              `/contact?message=Application for ${title}&type=job-application`
            }
          >
            Apply
          </Link>
        </div>
      </section>
    </>
  );
};

CareersPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const CareersPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout
      title={post.frontmatter.title}
      description={post.frontmatter.description}
    >
      <CareersPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Careers | neumind">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        live={post.frontmatter.live}
      />
    </Layout>
  );
};

CareersPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default CareersPost;

export const pageQuery = graphql`
  query CareersPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        live
        description
      }
    }
  }
`;
